<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">订单管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:10px;">
            <div title="购买机构" class="searchboxItem ci-full">
              <span class="itemLabel">购买机构:</span>
              <el-select size="small" v-model="searchForm.compId" remote :remote-method="getCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索" style="width: 100%">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input v-model="searchForm.courseName" type="text" size="small" clearable placeholder="请输入课程名称">
              </el-input>
            </div>
            <div title="是否上传凭证" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 130px;">是否上传凭证:</span>
              <el-select v-model="searchForm.voucher" placeholder="请选择是否上传凭证" size="small" clearable>
                <el-option v-for="item in voucherList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="订单日期" class="searchboxItem ci-full">
              <span class="itemLabel">订单日期:</span>
              <el-date-picker clearable size="small" v-model="searchForm.timeData" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button class="bgc-bv" round @click="handleExport">导出</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="getStatisticsData">
              <span>预付款总额(元)：<span class="amountOfMoney">{{ statisticsData.compAmount }}</span></span>
              <span>预付款余额(元)：<span class="amountOfMoney">{{ statisticsData.compBalance }}</span></span>
              <span>订单总额(元)：<span class="amountOfMoney">{{ statisticsData.orderAmount }}</span></span>
              <span>实付总额(元)：<span class="amountOfMoney">{{ statisticsData.actualMoney }}</span></span>
              <span>退款总额(元)：<span class="amountOfMoney">{{ statisticsData.refundMoney }}</span></span>
              <span>入班人数消耗金额(元)：<span class="amountOfMoney">{{ statisticsData.projectAmount }}</span></span>
            </div>
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />
              <el-table-column label="订单编号" align="left" prop="orderSn" show-overflow-tooltip width="160" />
              <el-table-column label="购买单位" align="left" prop="compName" show-overflow-tooltip width="200" />
              <el-table-column label="课程名称" align="left" prop="courseName" show-overflow-tooltip width="200" />
              <el-table-column label="订单时间" align="left" prop="orderTime" show-overflow-tooltip width="140" />
              <el-table-column label="购买数量" align="left" prop="productCnt" show-overflow-tooltip width="100" />
              <el-table-column label="订单金额(元)" align="left" prop="orderMoney" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                  <span class="text_1">{{ scope.row.orderMoney }}</span>
                </template>
              </el-table-column>
              <el-table-column label="订单状态" align="left" prop="orderStatus" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("ORDER_STATUS", scope.row.orderStatus) }}
                </template>
              </el-table-column>
              <el-table-column label="是否上传付款凭证" align="right" show-overflow-tooltip min-width="200">
                <template slot-scope="scope">
                  <span>{{ scope.row.voucherKey ? '是' : "否" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" align="left" prop="orderRemarks" show-overflow-tooltip width="200" />
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="examineMaterialOpen(scope.row)">审核订单</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog title="审核订单" :visible.sync="examineMaterialData.dialogVisible" width="700px" center
      :before-close="examineMaterialClose">
      <div class="sh_1">上传材料：</div>
      <div class="sh_2">单位介绍信：</div>
      <div class="sh_3" v-for="(item, index) in examineMaterialData.infoData.unitIntroductionLetter" :key="index">
        {{ item.pictureName }}
        <el-button type="text" style="padding:0px 5px" size="mini" @click="previewFile_1(item)">预览</el-button>
        <el-button type="text" style="padding:0px 5px" size="mini" @click="downloadFile_1(item)">下载</el-button>
      </div>
      <div class="sh_2">申请表：</div>
      <div class="sh_3" v-for="(item, index) in examineMaterialData.infoData.applicationForm" :key="index">
        {{ item.pictureName }}
        <el-button type="text" style="padding:0px 5px" size="mini" @click="previewFile_1(item)">预览</el-button>
        <el-button type="text" style="padding:0px 5px" size="mini" @click="downloadFile_1(item)">下载</el-button>
      </div>
      <div class="sh_2">其他材料：</div>
      <div class="sh_3" v-for="(item, index) in examineMaterialData.infoData.otherMaterials" :key="index">
        {{ item.pictureName }}
        <el-button type="text" style="padding:0px 5px" size="mini" @click="previewFile_1(item)">预览</el-button>
        <el-button type="text" style="padding:0px 5px" size="mini" @click="downloadFile_1(item)">下载</el-button>
      </div>
      <div class="sh_1" style="margin-top: 20px;">上传凭证：</div>
      <div class="sh_3" v-if="examineMaterialData.infoData.voucherName">
        {{ examineMaterialData.infoData.voucherName }}
        <el-button type="text" style="padding:0px 5px" size="mini" @click="previewFile_2">预览</el-button>
        <el-button type="text" style="padding:0px 5px" size="mini" @click="downloadFile_2">下载</el-button>
      </div>
      <div class="sh_3" v-else>暂无凭证</div>
      <el-input style="margin-top: 20px;" type="textarea" :rows="6" placeholder="请输入驳回原因" v-model="examineMaterialData.orderRemarks">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examineMaterialOpenNo" :disabled="[1,4].includes(examineMaterialData.orderStatus)">驳 回</el-button>
        <el-button @click="examineMaterialOpenOk" :disabled="[1,4].includes(examineMaterialData.orderStatus)" class="bgc-bv">通 过</el-button>
        <el-button @click="examineMaterialOpenDownload" class="bgc-bv">打包下载资料</el-button>
        <el-button @click="examineMaterialClose" class="bgc-bv">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="预览文件" :visible.sync="previewFileData.dialogVisible" @close="previewFileDataClose" width="600px" center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px" v-if="['PDF', 'pdf'].includes(previewFileData.extension)"></div>
        <div v-else>
          <img :src="previewFileData.ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="previewFileDataClose" class="bgc-bv">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
export default {
  name: "operate/accountListHR",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        compId: "", // 购买单位
        courseName: "", // 课程名称
        voucher: "", // 是否上传凭证
        timeData: "", // 订单日期
      },
      // 购买单位 - 下拉数据
      CompanyList: [],
      // 是否上传凭证 - 下拉数据
      voucherList: [
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 订单管理总额统计
      statisticsData: {},
      // 审核 - 弹框数据
      examineMaterialData: {
        dialogVisible: false, // 弹框状态
        orderId: "", // 订单id
        orderStatus: "", // 订单状态
        infoData: {}, // 订单管理审核详情
        orderRemarks: "", // 驳回原因
      },
      // 预览审核文件 - 弹框数据
      previewFileData: {
        dialogVisible: false, //弹框状态
        extension: "", // 文件类型
        ImgSrc: "", //预览图片Src路径
      },
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() { },
  methods: {
    // 获取 - 购买单位(两个字查询)
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.searchForm
      };
      if (this.searchForm.timeData) {
        params.orderDateStart = this.searchForm.timeData[0];
        params.orderDateEnd = this.searchForm.timeData[1];
      }
      this.doFetch({
        url: "/biz/hospital/order/comp/page",
        params,
        pageNum,
      });
      this.getStatisticsData();
    },
    // 获取 - 订单管理总额统计
    getStatisticsData() {
      const params = {
        ...this.searchForm
      };
      if (this.searchForm.timeData) {
        params.orderDateStart = this.searchForm.timeData[0];
        params.orderDateEnd = this.searchForm.timeData[1];
      }
      this.$post("/biz/hospital/order/comp/orderTotalCount", params).then(
        (res) => {
          this.statisticsData = res.data || {};
        }
      );
    },
    // 导出
    handleExport() {
      const params = {
        ...this.searchForm
      };
      if (this.searchForm.timeData) {
        params.orderDateStart = this.searchForm.timeData[0];
        params.orderDateEnd = this.searchForm.timeData[1];
      }
      this.$post("/biz/hospital/order/comp/exportOrder", params).then(
        (ret) => {
          if (ret.status == "0") {
            if (!this.downloadItems.includes(ret.data.taskId)) {
              this.$store.dispatch("pushDownloadItems", ret.data.taskId);
            } else {
              this.$message.warning(
                "[" + ret.data.data.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(ret.data.message);
          }
        }
      );
    },
    // 审核订单 - 打开弹框
    examineMaterialOpen(row) {
      this.examineMaterialData.orderId = row.orderId;
      // orderStatus => 0: "待付款"1: "已付款"2: "已取消"3: "退款中"4: "退款成功"5: "退款失败"
      this.examineMaterialData.orderStatus = row.orderStatus;
      // 获取 - 机构订单管理审核详情
      this.$post("/biz/hospital/order/comp/examineInfo", { orderId: row.orderId }).then(
        (res) => {
          this.examineMaterialData.infoData = res.data;
          this.examineMaterialData.dialogVisible = true;
        }
      );
    },
    // 审核订单 - 关闭弹框
    examineMaterialClose() {
      this.examineMaterialData = this.$options.data().examineMaterialData;
    },
    // 审核订单 - 驳回
    examineMaterialOpenNo() {
      if(this.examineMaterialData.orderRemarks){
        this.$post("/biz/hospital/order/comp/examine/refuse", { 
          orderId: this.examineMaterialData.orderId,
          orderRemarks: this.examineMaterialData.orderRemarks,
        }).then(
          (res) => {
            this.$message({
              type: "success",
              message: "驳回成功",
            });
            this.getData(this.pageNum);
            this.examineMaterialClose();
          }
        );
      } else {
        this.$message({
          type: "warning",
          message: "请输入驳回原因",
        });
      }
    },
    // 审核订单 - 通过
    examineMaterialOpenOk() {
      this.$post("/biz/hospital/order/comp/examine/check", { orderId: this.examineMaterialData.orderId }).then(res => {
        // 判断是否有提示
        if (res.data.checkTips) {
          this.$message({
            type: "warning",
            message: res.data.checkTips,
          });
        }
        // 判断可以去付款
        if (res.data.paraIsNext) {
          this.$post("/biz/hospital/order/set/paid", { orderId: this.examineMaterialData.orderId }).then(res => {
            this.$message({
              type: "success",
              message: "审核成功",
            });
            this.getData(this.pageNum);
            this.examineMaterialClose();
          });
        }
      });
    },
    // 审核订单 - 打包下载(资料)
    examineMaterialOpenDownload() {
      let cData = {
        compName: this.examineMaterialData.infoData.compName, // 机构名称
        projectId: this.examineMaterialData.infoData.projectId, // 班级id
      };
      this.$post("/biz/projectCourse/material/downloadMaterial", cData).then(
        (ret) => {
          if (ret.status == "0") {
            if (!this.downloadItems.includes(ret.data.data.taskId)) {
              this.$store.dispatch("pushDownloadItems", ret.data.data.taskId);
            } else {
              this.$message.warning(
                "[" + ret.data.data.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(ret.data.message);
          }
        }
      );
    },
    // 审核订单 - 预览资料
    previewFile_1(item) {
      this.previewFileData.extension = item.pictureName.replace(/.+\./, "");
      if (['PDF', 'pdf'].includes(this.previewFileData.extension)) {
        this.$nextTick(() => {
          pdf.embed(res.pictureUrl, "#pdf-cert1");
        });
      } else {
        this.previewFileData.ImgSrc = item.pictureUrl;
      }
      this.previewFileData.dialogVisible = true;
    },
    // 审核订单 - 下载资料
    downloadFile_1(item) {
      window.open(item.downloadPictureUrl)
    },
    // 审核订单 - 预览凭证
    previewFile_2() {
      this.previewFileData.extension = this.examineMaterialData.infoData.voucherType;
      this.previewFileData.ImgSrc = this.examineMaterialData.infoData.voucherUrl;
      this.previewFileData.dialogVisible = true;
    },
    // 审核订单 - 下载凭证
    downloadFile_2() {
      window.open(this.examineMaterialData.infoData.downloadVoucherUrl)
    },
    // 审核订单 - 预览资料 || 预览凭证 - 关闭弹框
    previewFileDataClose() {
      this.previewFileData = this.$options.data().previewFileData;
    }
  },
};
</script>
<style lang="less" scoped>
.getStatisticsData {
  margin-bottom: 10px;
  font-size: 13px;

  .amountOfMoney {
    font-size: 18px;
    font-weight: 600;
    color: red;
    margin-right: 10px;
  }
}

.sh_1 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.sh_2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sh_3 {
  margin-bottom: 4px;
}
.text_1 {
  font-size: 16px;
  font-weight: 600;
  color: red;
}
</style>